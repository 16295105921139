import { makeVar } from '@apollo/client';

export const LearnerDetailVar = makeVar(null);
export const allLearners = makeVar([]);
export const programContacts = makeVar([]);
export const logsCountVar = makeVar(null);
export const ResourcesDetailvar = makeVar(null);

// GUIDES
export const lessonPlanMenteeVar = makeVar(null);
export const selectedActivityVar = makeVar(null);
export const selectedBadgeVar = makeVar(null);
export const limitVar = makeVar(10);
export const guideDrawerVar = makeVar(false);
export const selectedGuideVar = makeVar(null);

// GOALS
export const lessonGoalMenteeVar = makeVar(null);
export const selectedGoalActivityVar = makeVar(null);
export const selectedGoalBadgeVar = makeVar(null);
export const imageVar = makeVar(null);
export const selectedGoalVar = makeVar(null);
export const GoalLimitVar = makeVar(10);
export const goalDrawerVar = makeVar(false);

// Referrals
export const SelectedLearnerDetailVar = makeVar(null);
export const ArticleFilterVar = makeVar({
  referralDetails: null,
  filter: { tags: '', categoryId: '', subCategoryId: '' },
  article: null,
  filterdatas: {
    tags: [],
    subCategories: [],
  },
});
