import { makeVar } from '@apollo/client';

export const chatLoadingVar = makeVar(true);
export const chatListLoadingVar = makeVar(true);
export const directChatList = makeVar({});
export const groupUnread = makeVar(0);
export const directUnread = makeVar({});
export const groupChatList = makeVar({});
export const shouldListenVar = makeVar(true);
export const messageText=makeVar({})
export const groupText=makeVar({})