import mixpanel from 'mixpanel-browser';
import { userAuth } from '../Vars/auth';

export const trackEvent = (event, data = { screen: '', Action: '',sender: '', reciever: '', chatId: '',userid:'',orgid:'',programid:'',orgName:'',programname:'' }) => {
  mixpanel.init(process.env.REACT_APP_MIX_PANEL, { debug: true, ignore_dnt: true });
  

  const { userDetails } = userAuth();
  
  const submitData = {
    app: 'MentorWeb',
    ...(userDetails||data?.userid)&&{userId: userDetails?.id||data?.userid},
    event: event,
    env: process.env.REACT_APP_ENVIRONMENT,
    time: new Date(),
    screen: data.screen,
    Action:data.Action,
    ...(data.sender !== '' && { sender: data.sender }),
    ...(data.reciever !== '' && { reciever: data.reciever }),
    ...(data.chatId !== '' && { chatId: data.chatId }),
    ...(userDetails||data?.orgid)&&{orgId:userDetails?.organization?.id||data?.orgid},
    ...(userDetails||data?.programid)&&{programId:userDetails?.mentorInProgramIds[0]||data?.programid},
    ...(userDetails||data?.orgName)&&{organizationName:userDetails?.organization?.organizationName||data?.orgName},
    
    ...(userDetails||data?.programname)&&{programName:userDetails?.program||data?.programname}
    
    
  };

  mixpanel.track(event, submitData);
};

export const trackAPIError = (data) => {
  mixpanel.init(process.env.REACT_APP_MIX_PANEL, { debug: true, ignore_dnt: true });


  const { userDetails } = userAuth();
  const submitData = {
    app: 'MentorWeb',
    ...(userDetails)&&{userId: userDetails.id},
    event: 'API-ERROR',
    env: process.env.REACT_APP_ENVIRONMENT,
    time: new Date(),
    error: data,
   
    ...(userDetails&&{orgId:userDetails?.organization?.id}),
    ...(userDetails&&{programId:userDetails?.mentorInProgramIds[0]}),
    ...(userDetails&&{organizationName:userDetails?.organization?.organizationName}),
    
    ...(userDetails&&{programName:userDetails?.program})
  };
  mixpanel.track('API-ERROR', submitData);
};
