const getRoutes = {
  home: '/',
  learners: '/learners',
  learnerDetails: '/learners/:id',
  referralsDetails: '/learners/:id/referrals-details/:referral_id',
  meetings: '/meetings',
  joinMeeting: '/meetings/:roomId',
  messages: '/messages',
  referralTask: '/referral-task',
  checkInLogs: '/check-in-logs',
  notifications: '/notifications',
  safetyInformation: '/safety-information',
  about: '/about',
  howItWorks: '/howItWorks',
  termsAndConditions: '/termsAndConditions',
  features: '/features',
  appTour: '/app-tour',
  feedback: '/feedback',
  notAuthorized: '/not-authorized',

  tools: '/tools',
  resources: '/resources',
  takeAssesement: '/take-assessement',
  takeMoodRing: '/take-moodring',
  resourceDetails: '/safety-information/:title',
  optOut: '/optOut',
  login: '/login',
  verifyOtp: '/verify-otp',
  invalidPath: '*',
  profile: '/profile',
  ftue: '/ftue-screen',
};

export default getRoutes;
