export default{
    MentorHome:'Home',
    Resources:'Resources',
    MenteeDetail:'Mentee-Detail',
    MenteeList:'Mentee-list',
    ProgramContacts:'Program-Contacts',
    MenteeSummary:'Mentee-summary',
    MenteeProfile:'Mentee-Profile',
    MenteeMoodRing:"Mentee-Mood-Ring",
    MenteePriorities:'Mentee-Priorities',
    MenteeGoals:'Mentee-Goals',
    MenteeBadges:'Mentee-Badges',
    MenteeAchievements:'Mentee-Achievements',
    MenteeGuides:'Mentee-Guides',
    MenteeAppUsage:'Mentee-AppUsage',
    Messages:'Messages',
    Chat:'Chat',
    Broadcast:'Broadcast',
    Meeting:'Meeting',
    UpcomingMeeting:'Upcoming-Meeting',
    CompletedMeeting:'Completed-Meeting',
    Notifications:'Notifications',
    ResourceDetail:'Resource-Detail',
    Apptutorial:'App-tutorial',
    Profile:'Profile',
    Ftue:'Ftue',
    Login:'Login',
    OtpVerification:'Otp-Verification',
    TermsAndConditions:'Terms-And-Conditions',
    FeedBack:'Feed-Back'
}