import axios from '../Config/axiosConfig';
import handleAxiosError from '../Helper/handleAxiosError';

export const getOtpForLogin = ({ countryCode, phoneNumber }) => {
  let url =
    process.env.NODE_ENV === 'production'
      ? `users/getOtp/countryCode/+${countryCode}/phoneNumber/${phoneNumber}`
      : `users/getOtpDev/${phoneNumber}`;
  return axios({
    url,
  }).catch((err) => handleAxiosError(err));
};

export const verifyOtp = (data) => {
  return axios({
    method: 'POST',
    url: 'users/verifyOtp',
    data,
  });
};

export const getCurrentUserDetails = () => {
  return axios({
    url: 'users/loggedinApp',
  }).catch((err) => handleAxiosError(err));
};

export const patchCurrentUser = (data) => {
  return axios({
    method: 'PATCH',
    url: `user/${data.userId}`,
    data: { ...data, dateLocale: 'en-us ' },
  }).catch((err) => handleAxiosError(err));
};

export const getFirebaseConfig = () => {
  return axios({
    url: 'settings/firebaseMenteeWeb',
  }).catch((err) => handleAxiosError(err));
};

export const getFirebaseUser = () => {
  return axios({
    url: 'settings/firebaseUser',
  }).catch((err) => handleAxiosError(err));
};

export const registerDeviceToken = (payload) => {
  return axios({
    method: 'POST',
    url: `user/${payload.userId}/setDeviceToken`,
    data: payload.data,
  }).catch((err) => handleAxiosError(err));
};

export const logOutUser = (userId) => {
  return axios({
    method: 'GET',
    url: `users/logout/${userId}`,
  }).catch((err) => handleAxiosError(err));
};

// export const getNewToken = (refreshToken) => {
//   return axios({
//     method: 'POST',
//     url: `auth/refreshToken`,
//     data: { refreshToken },
//   }).catch((err) => {
//     delete axios.defaults.headers.common['Authorization'];
//     localStorage.clear();
//     setTimeout(() => {
//       window.location.href = '/login';
//     }, 100);
//   });
// };
