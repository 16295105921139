import { gql } from '@apollo/client';
export const GET_UPCOMING_MEETINGS = gql`
  query getUpcomingMeetings($userId: String!, $skip: Int, $limit: Int) {
    getUpcomingMeetings(userId: $userId, skip: $skip, limit: $limit) {
      _id
      startDate
      endDate
      roomId
      createdBy {
        profileImage
        _id
        firstName
        lastName
        role
      }
      participants {
        _id
        firstName
        lastName
        profileImage
      }
    }
  }
`;
export const GET_PAST_MEETINGS = gql`
  query getPastMeetings($userId: String!, $skip: Int, $limit: Int) {
    getPastMeetings(userId: $userId, skip: $skip, limit: $limit) {
      startDate
      endDate
      roomId
      createdBy {
        profileImage
        _id
        firstName
        lastName
        role
      }
      participants {
        _id
        firstName
        lastName
        profileImage
      }
      duration
    }
  }
`;
export const GET_UPCOMING_MEETINGS_COUNT = gql`
  query getUpcomingMeetingsCount($userId: String!) {
    getUpcomingMeetingsCount(userId: $userId)
  }
`;
export const GET_PAST_MEETINGS_COUNT = gql`
  query getPastMeetingsCount($userId: String!) {
    getPastMeetingsCount(userId: $userId)
  }
`;

export const GET_ROOM_ACCESS_TOKEN = gql`
  query getRoomAccess($roomId: String, $userName: String) {
    getRoomAccess(roomId: $roomId, userName: $userName)
  }
`;

export const GET_ROOM_ACCESS = gql`
  query getRoomAccess($room: String!, $user: String) {
    getMeeting(meetingId: $room) {
      _id
      roomId
      startDate
      endDate
      createdBy {
        _id
      }
      participants {
        _id
        firstName
        lastName
        profileImage
      }
    }
    getRoomAccess(roomId: $room, userName: $user)
  }
`;

export const SCHEDULE_MEETING = gql`
  mutation scheduleMeeting($meetingInput: meetingInput!) {
    createMeeting(meeting: $meetingInput) {
      startDate
      endDate
    }
  }
`;
export const UPDATE_MEETING = gql`
  mutation updateScheduleMeeting($meetingId: String, $meeting: meetingUpdate) {
    updateMeeting(meetingId: $meetingId, meeting: $meeting) {
      startDate
      endDate
    }
  }
`;

export const DELETE_MEETING = gql`
  mutation deleteMeeting($meetingId: String) {
    deleteMeeting(meetingId: $meetingId)
  }
`;

export const UPDATE_MEETING_STAUS = gql`
  mutation updateMeetingStatus($meetingId: String!, $status: String!) {
    updateMeetingStatus(meetingId: $meetingId, status: $status) {
      _id
    }
  }
`;
