const themeColors = {
  headerLeft: '#000000',
  headerCenter: '#FFFFFF',
  primary: '#000000',
  secondary: '#27CAD2',
  chatBubbleReceiver: '##27CAD2',
  chatBubbleSender: '#E1FBFA',
  chatRoomRoot: '#FFFFFF',
  chatBubbleTextSender: '#FFFFFF',
  chatBubbleTextReceiver: ' #1D1E21',
};

export default themeColors;
