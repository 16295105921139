import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GENERATE_TOKEN } from '../Apollo Queries/Auth';
import { trackAPIError } from '../Helper/EventTracker';
import getRoutes from '../Routes/RoutePaths';
import { snackBar } from '../Vars/global';
import client from './apolloConfig';

const logoutUser = () => {
  snackBar({
    open: true,
    message: 'Session has expired. Please login back to continue',
    variant: 'warning',
  });
  localStorage.clear();
  setTimeout(() => {
    window.location.href = getRoutes.login;
  }, 100);
};

export const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('@refreshToken');

    const response = await client.mutate({
      mutation: GENERATE_TOKEN,
      context: { clientName: 'userService' },
      variables: {
        refreshToken: refreshToken,
      },
    });
    const data = response.data?.generate_token.token ?? '';
    localStorage.setItem('@authToken', data);
    return data;
  } catch (err) {
    throw new Error('Refresh token failed');
  }
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (operation.operationName === 'generate_token') {
    logoutUser();
    return;
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, extensions }) => {
      const errorData = {
        type: 'GraphQL Error',
        message,
        locations,
        code: extensions.code,
        operationName: operation.operationName,
      };
      trackAPIError(errorData);
    });

    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          return fromPromise(refreshAccessToken().catch(() => logoutUser()))
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`,
                },
              });
              return forward(operation);
            });
      }
    }
  }
});

export default errorLink;
