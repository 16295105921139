import { useReactiveVar } from '@apollo/client';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PageLoader from '../Components/Common/PageLoader';
import { userAuth } from '../Vars/auth';
import ApptourRoute from './AddTourRouteWrapper';
import PrivateRoute from './PrivateRouteWrapper';
import PublicRoutes from './PublicRouteWrapper';
import getRoutes from './RoutePaths';

const Login = React.lazy(() => import('../Components/Authentication/Login'));
const VerifyOtp = React.lazy(() => import('../Components/Authentication/VerifyOtp'));
const NotAuthorized = React.lazy(() => import('../Components/Common/NotAuthorized'));
const Dashboard = React.lazy(() => import('../Components/Dashboard/index'));
const Learners = React.lazy(() => import('../Components/Learners/index'));
const LearnerDetail = React.lazy(() =>
  import('../Components/Learners/LearnerDetail/LearnerDetail'),
);
const Meetings = React.lazy(() => import('../Components/Meeting'));
const JoinMeeting = React.lazy(() => import('../Components/Meeting/JoinMeeting'));
const Message = React.lazy(() => import('../Components/Messaging/index'));
const HowItWorks = React.lazy(() => import('../Components/QuickLinks/HowItWorks'));
const Features = React.lazy(() => import('../Components/QuickLinks/Features'));
const Terms = React.lazy(() => import('../Components/QuickLinks/Terms'));
const About = React.lazy(() => import('../Components/QuickLinks/About'));
const Resources = React.lazy(() => import('../Components/Resources/index'));
const ResourcesDetails = React.lazy(() => import('../Components/Resources/ResourcesDetails'));
const ReferralDetails = React.lazy(() =>
  import('../Components/Learners/LearnerDetail/Referrals/ReferralsDetails'),
);
const AppTour = React.lazy(() => import('../Components/Apptutorial'));
const Notification = React.lazy(() => import('../Components/Notification/NotificationList'));
const Profile = React.lazy(() => import('../Components/MyProfile/index'));
const ReferalTasks = React.lazy(() => import('../Components/ReferalTasks'));
const FtueScreen = React.lazy(() => import('../Components/Ftue/FirstFtue'));

const Navigator = () => {
  const userAuthVar = useReactiveVar(userAuth);
  const { validatingUser, userDetails } = userAuthVar;
  return (
    <React.Fragment>
      {validatingUser ? (
        <PageLoader />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Routes>
              <Route element={<PublicRoutes />}>
                <Route path={getRoutes.login} element={<Login />} />
                <Route path={getRoutes.verifyOtp} element={<VerifyOtp />} />
                <Route path={getRoutes.notAuthorized} element={<NotAuthorized />} />
                <Route path={getRoutes.invalidPath} element={<Navigate to={getRoutes.login} />} />
              </Route>

              {userDetails && userDetails?.firstLoggedInDate === '' ? (
                <Route element={<ApptourRoute />}>
                  <Route path={getRoutes.home} element={<FtueScreen />} />
                  <Route path={getRoutes.ftue} element={<FtueScreen />} />
                  <Route path={getRoutes.appTour} element={<AppTour />} />
                </Route>
              ) : (
                <Route element={<PrivateRoute />}>
                  <Route path={getRoutes.home} element={<Dashboard />} />
                  <Route path={getRoutes.learners} element={<Learners />} />
                  <Route
                    path={getRoutes.learnerDetails}
                    element={
                      <div>
                        <LearnerDetail />
                      </div>
                    }
                  />
                  <Route path={getRoutes.referralsDetails} element={<ReferralDetails />} />
                  <Route path={getRoutes.meetings} element={<Meetings />} />
                  <Route path={getRoutes.joinMeeting} element={<JoinMeeting />} />
                  <Route path={getRoutes.messages} element={<Message />} />
                  <Route path={getRoutes.referralTask} element={<ReferalTasks />} />
                  <Route path={getRoutes.checkInLogs} element={<div>Check In Logs</div>} />
                  <Route path={getRoutes.notifications} element={<Notification />} />
                  <Route path={getRoutes.profile} element={<Profile />} />

                  <Route
                    path={getRoutes.safetyInformation}
                    element={
                      <div>
                        <Resources />
                      </div>
                    }
                  />
                  <Route
                    path={getRoutes.resourceDetails}
                    element={
                      <div>
                        <ResourcesDetails />
                      </div>
                    }
                  />
                  <Route
                    path={getRoutes.about}
                    element={
                      <div>
                        <About />
                      </div>
                    }
                  />
                  <Route
                    path={getRoutes.howItWorks}
                    element={
                      <div>
                        <HowItWorks />
                      </div>
                    }
                  />
                  <Route
                    path={getRoutes.termsAndConditions}
                    element={
                      <div>
                        <Terms />
                      </div>
                    }
                  />

                  <Route
                    path={getRoutes.features}
                    element={
                      <div>
                        <Features />
                      </div>
                    }
                  />
                  <Route path={getRoutes.appTour} element={<AppTour />} />
                  <Route path={getRoutes.feedback} element={<div>Feedback</div>} />
                  <Route path={getRoutes.invalidPath} element={<Navigate to={getRoutes.home} />} />
                </Route>
              )}
            </Routes>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default Navigator;
