import { useMutation } from '@apollo/client';
import {
  Avatar, Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText, DialogTitle, Grid,
  ListItem, ListItemText, Menu, MenuItem, Typography
} from '@mui/material';
import { startCase } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGOUT } from '../../Apollo Queries/Auth';
import mentorAction from '../../Helper/Analytics/mentorAction';
import { trackEvent } from '../../Helper/EventTracker';
import getRoutes from '../../Routes/RoutePaths';
// import getLoggedUserDetails from '../services/getLoggedUserDetails';
// import listCollege from '../services/getListCollege';
// import getlistMajors from '../services/getListMajors';
// import getAdditionalPrograms from '../services/getAdditionalPrograms';

const CurrentUserInfo = ({ userDetails, open }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showLogoutModal, setShowLogoutModal] = React.useState(false);
  const navigate = useNavigate();
  const [loggedData] = React.useState('');

  const [logout, { loading }] = useMutation(LOGOUT, {
    context: { clientName: 'userService' },
  });
  // const [collegeList, setcollegeList] = React.useState();
  // const [majorsList, setMajorsList] = React.useState();
  // const [additionalProgramsList, setAdditionalProgramsList] = React.useState();

  // useEffect(() => {
  //   getLoggedUserDetails(userDetails.id).then((res) => {
  //     setLoggedData(res);
  //   });
  //   listCollege().then((res) => {
  //     setcollegeList(res);
  //   });
  //   getlistMajors().then((res) => {
  //     setMajorsList(res);
  //   });
  //   getAdditionalPrograms().then((res) => {
  //     setAdditionalProgramsList(res);
  //   });
  // }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfileModal = () => {
    navigate(getRoutes.profile);
    handleClose();
  };

  const getUserName = () => {
    const name = startCase(userDetails.firstName) + ' ' + startCase(userDetails.lastName);
    const nameLength = name.length;
    if (nameLength > 20) return name.substring(0, 20) + '...';
    else return name;
  };

  // const onLogOut = async () => {
  //   const resp = await logOutUser(userDetails.id);
  //   if (Object.keys(resp.data).includes('status')) {
  //     localStorage.removeItem('@authToken');
  //     localStorage.removeItem('@refreshToken');
  //     window.location.replace(resp.data.redirectLink);
  //   } else {
  //     localStorage.removeItem('@authToken');
  //     localStorage.removeItem('@refreshToken');
  //     window.location.replace(getRoutes.login);
  //   }
  // };
  const onLogOut = async () => {
    trackEvent('click',{
      Action:mentorAction.LOG_OUT
    });
    await logout();
    window.location.replace(getRoutes.login);

    localStorage.removeItem('@authToken');
    localStorage.removeItem('@refreshToken');
    localStorage.removeItem('organizationLogo')
  };

  const handleLogout = () => {
    setShowLogoutModal(!showLogoutModal);
    handleClose();
  };
  return (
    <React.Fragment>
      {/* <EditProfile
        loggedData={loggedData}
        setLoggedData={setLoggedData}
        collegeList={collegeList}
        majorsList={majorsList}
        additionalProgramsList={additionalProgramsList}
        getLoggedUserDetails={getLoggedUserDetails}
      /> */}
      <Grid style={{ display: 'flex', flexGrow: 1, alignItems: 'flex-end' }}>
        <div
          className="MuiList-root MuiList-padding"
          style={{
            borderTop: '1px solid rgba(228, 223, 223, 0.09)',
            width: '100%',
            background: '#000000',
            color: '#FFFFFF',
          }}>
          <ListItem button onClick={handleClick}>
            <Typography variant="h3" noWrap style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={loggedData.data ? loggedData.data.profileImage : userDetails.profileImage}
                style={{ marginRight: open ? 10 : 30 }}
              />
              <ListItemText primary={getUserName()} />
            </Typography>
          </ListItem>
        </div>
      </Grid>

      <Dialog
        disableBackdropClick={true}
        role="alert"
        tabIndex="0"
        aria-labelledby="alert-dialog-description"
        open={showLogoutModal}
        onClose={() => handleLogout()}>
        <div>
          <DialogTitle id="alert-dialog-title">{'Logout?'}</DialogTitle>
          <DialogContent tabIndex="0">
            <DialogContentText id="alert-dialog-description">
              {'Are you sure you want to logout?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button aria-label="No" onClick={() => handleLogout()} color="primary">
              {'No'}
            </Button>

            <Button
              aria-label="yes"
              onClick={() => {
                onLogOut();
                // logOutUser(userDetails.id);
                // , () => {
                //   dispatch(logoutUser(userDetails));
                // });
              }}
              color="primary"
              autoFocus>
              {'Yes'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      {/* Menu Options */}
      <Menu
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: 'black',
            color: '#FFFFFF',
            border: '1px solid grey',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 50,
          horizontal: 20,
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={() => handleProfileModal()}>My Profile</MenuItem>
        <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default CurrentUserInfo;
