import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Snackbar,
} from '@mui/material';
import { findIndex, startCase } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Video from 'twilio-video';
import ExpandIcon from '../../../Assets/Images/Expand.svg';
import getRoutes from '../../../Routes/RoutePaths';
import DraggableCard from './DraggableCard';
import MeetingControls from './MeetingControls';
import { Participant } from './Participant';
import ParticipantsList from './ParticipantsList';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Room = ({
  roomName,
  token,
  meetingData,
  user,
  setShowFullScreen,
  showFullScreen,
  setShowCard,
  showCard,
}) => {
  let navigate = useNavigate();

  const [room, setRoom] = useState(null);
  const [meetingLoading, setMeetingLoading] = useState(true);
  const [showVideo, changeShowVideoStatus] = useState(true);
  const [enableAudio, changeAudioStatus] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [participantConnected, setParticipantConnected] = useState({
    status: false,
  });
  const [showNotification, setNotification] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [screenTrack, setScreenTrack] = useState(null);
  const [localVideoTrack, setLocalVideoTrack] = useState(null);

  const remoteParticipants =
    participants &&
    participants.length &&
    participants.map((participant) => {
      return (
        <Participant
          key={participant.sid}
          participant={participant}
          participantDetails={meetingData.participants.find(
            (eachUser) => eachUser._id === participant?.identity,
          )}
          setSelectedParticipant={setSelectedParticipant}
          selectedParticipant={selectedParticipant}
          screenTrack={screenTrack}
          showVideo={showVideo}
          room={room}
          showParticipants={showParticipants}
        />
      );
    });

  useEffect(() => {
    if (room && !selectedParticipant) {
      const participant = room.participants.values().next().value;
      participant && participant?.identity
        ? setSelectedParticipant(participant)
        : setSelectedParticipant(room.localParticipant);
    }
  }, [room, selectedParticipant]);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => {
        if (prevParticipants && Array.isArray(prevParticipants)) {
          const index = findIndex(
            prevParticipants,
            (item) => item?.identity === participant?.identity,
          );
          if (index >= 0) {
            const participants = [...prevParticipants];
            participants.splice(index, 1);
            return [...participants, participant];
          } else return [...prevParticipants, participant];
        } else return prevParticipants;
      });

      setParticipantConnected({
        ...meetingData.participants.find((eachUser) => eachUser._id === participant?.identity),
        status: true,
      });
      setNotification(true);
      setMeetingLoading(false);
    };

    const participantDisconnected = (participant) => {
      participant.removeAllListeners();
      setParticipants(
        (prevParticipants) =>
          prevParticipants &&
          Array.isArray(prevParticipants) &&
          prevParticipants.filter((p) => p !== participant),
      );

      setSelectedParticipant((selectedParticipant) => {
        if (selectedParticipant?.identity === participant?.identity) return participants[0];
        return selectedParticipant;
      });
      setParticipantConnected({
        ...meetingData.participants.find((eachUser) => eachUser._id === participant?.identity),
        status: false,
      });
      setNotification(true);
    };

    Video.connect(token, {
      name: roomName,
      video: { width: 1024, name: 'camera' },
      audio: true,
      dominantSpeaker: true,
    }).then((room) => {
      setRoom(room);
      let localTrack = null;
      room.localParticipant.videoTracks.forEach((videoTrack) => {
        localTrack = videoTrack.track;
      });
      setLocalVideoTrack(localTrack);
      participantConnected(room.localParticipant);
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
      room.once('disconnected', (room, error) => {
        room?.localParticipant.removeAllListeners();
        room?.participants &&
          room.participants.forEach((participant) => {
            participant.removeAllListeners();
          });
        if (error) {
          if (error.code == 53205) {
            setRoom((currentRoom) => {
              if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
                  trackPublication.track.stop();
                });
                setTimeout(() => {
                  currentRoom.disconnect();
                });
                return null;
              } else {
                return currentRoom;
              }
            });
            navigate(getRoutes.meetings, { replace: true });
          }
        }
      });
    });
    return () => {
      setRoom((currentRoom) => {
        currentRoom?.localParticipant?.removeAllListeners();
        currentRoom?.participants &&
          currentRoom.participants.forEach((participant) => {
            participant.removeAllListeners();
          });
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  const fullView = useMemo(() => (participants.length <= 1 ? true : false), [participants.length]);
  const selectedParticipantDetails = useMemo(
    () =>
      meetingData.participants.find((eachUser) => eachUser._id === selectedParticipant?.identity),
    [selectedParticipant?.identity, meetingData.participants.length],
  );

  const handleShowCard = () => {
    setShowCard(true);
    setShowFullScreen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        style={{ display: showFullScreen && !showCard ? 'block' : 'none' }}
        open={true}
        fullScreen
        TransitionComponent={Transition}>
        <div className="room_outer_wrap">
          <Backdrop style={{ zIndex: 3000 }} open={meetingLoading}>
            <CircularProgress color="primary" />
          </Backdrop>
          <div className="room">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box display={'flex'} justifyContent={'flex-end'} padding={'20px'}>
                  <IconButton onClick={handleShowCard}>
                    <img style={{ width: '20px' }} src={ExpandIcon} alt="minimize" />
                  </IconButton>
                </Box>
              </Grid>
              <Grid
                style={{ display: 'flex', justifyContent: 'center' }}
                item
                xs={fullView ? 12 : showParticipants ? 6 : 9}>
                {room && (
                  <div
                    className="selected_participant"
                    style={{ marginLeft: fullView ? '0px' : '50px' }}>
                    <div
                      className={
                        fullView
                          ? 'main_view_drawer_closed'
                          : showParticipants
                          ? 'drawer_open'
                          : 'drawer_closed'
                      }>
                      {selectedParticipant && (
                        <Participant
                          key={selectedParticipant.sid}
                          participant={selectedParticipant}
                          participantDetails={selectedParticipantDetails}
                          setSelectedParticipant={setSelectedParticipant}
                          selectedParticipant={selectedParticipant}
                          screenTrack={screenTrack}
                          showVideo={showVideo}
                          fromSelected={true}
                          room={room}
                          showParticipants={showParticipants}
                        />
                      )}
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item xs={fullView ? 0 : 3}>
                <div style={fullView ? { display: 'none' } : {}} className={'participant_column'}>
                  {remoteParticipants}
                </div>
              </Grid>
              <Slide direction="left" in={showParticipants} mountOnEnter unmountOnExit>
                <Grid item xs={3}>
                  <ParticipantsList
                    connectedParticipants={participants}
                    participants={meetingData.participants}
                    setShowParticipants={setShowParticipants}
                    setSelectedParticipant={setSelectedParticipant}
                    selectedParticipant={selectedParticipant}
                  />
                </Grid>
              </Slide>
            </Grid>
          </div>
          {Object.values(meetingData).length > 0 && (
            <MeetingControls
              room={room}
              setRoom={setRoom}
              enableAudio={enableAudio}
              changeAudioStatus={changeAudioStatus}
              showVideo={showVideo}
              changeShowVideoStatus={changeShowVideoStatus}
              setShowParticipants={setShowParticipants}
              showParticipants={showParticipants}
              participants={participants}
              screenTrack={screenTrack}
              setScreenTrack={setScreenTrack}
              token={token}
              setShowFullScreen={setShowFullScreen}
              setShowCard={setShowCard}
            />
          )}
          <Snackbar
            className="join-meeting-snack"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showNotification}
            autoHideDuration={3000}
            onClose={() => setNotification(false)}
            message={`${startCase(participantConnected.firstName)} ${startCase(
              participantConnected.lastName,
            )} has ${participantConnected.status ? 'joined' : 'left'} the meeting`}
          />
        </div>{' '}
      </Dialog>
      {!showFullScreen && showCard && (
        <DraggableCard
          room={room}
          setRoom={setRoom}
          enableAudio={enableAudio}
          changeAudioStatus={changeAudioStatus}
          showVideo={showVideo}
          changeShowVideoStatus={changeShowVideoStatus}
          setShowCard={setShowCard}
          setShowFullScreen={setShowFullScreen}
          participantDetails={meetingData.participants.find(
            (eachUser) => eachUser._id === selectedParticipant?.identity,
          )}
        />
      )}
    </React.Fragment>
  );
};

export default Room;
