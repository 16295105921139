import { useReactiveVar } from '@apollo/client';
import { getApp } from 'firebase/app';
import { collection, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect } from 'react';
// import useGetAllMentorUsersList from '../../../Hooks/SSG/useGetAllMentorUsers';
import { userAuth } from '../../../Vars/auth';
import {
  chatListLoadingVar,
  directChatList,
  groupChatList,
  groupUnread,
} from '../../../Vars/ChatList';

const ListenToMessages = () => {
  let app;
  let db;

  const { userDetails } = useReactiveVar(userAuth);

  // const { data: userData } = useGetAllMentorUsersList();
  useEffect(() => {
    app = getApp();
    db = getFirestore(app);
  }, []);
  const setMentorDirectChatList = (chatData) => {
    try {
      const newChatObj = {};
      const uread = {};
      if (chatData.chatWith.mongoUserId) {
        newChatObj[chatData.chatWith.mongoUserId] = {
          lastMessage: chatData.lastMessageText,
          userId: chatData.chatWith.mongoUserId,
          lastMessageOn: chatData.lastMessageCreatedDate
            ? new Date(chatData.lastMessageCreatedDate.toDate())
            : new Date(0),
          unreadMessage: chatData.totalUnreadMessages,
          lastMessageType: chatData.lastMessageType,
          firestoreId: chatData.chatWith.firestoreId,
          firstName: chatData.chatWith.firstName,
          lastName: chatData.chatWith.lastName,
          profileImage: chatData.chatWith.profileImage,
          realRole: chatData.chatWith.role ? chatData.chatWith.role[0] : 'mentee', // this is done because we are getting role as undefined for certain user
          user: chatData.chatWith.role ? chatData.chatWith.role[0] : 'mentee',
          hasChat: true,
          type: 'DIRECT_MESSAGE',
          allMentorsMongoIds: chatData?.chatWith?.allMentorsMongoIds || [],
          showInRecent: true,
        };
        uread[chatData.chatWith.mongoUserId] = { count: chatData.totalUnreadMessages };
      }
      return newChatObj;
    } catch (err) {
      console.error(err);
      chatListLoadingVar(false);
      return {};
    }
  };
  useEffect(() => {
    // if (!userData?.length) return;
    // const newChatObj = {};

    // userData.forEach((user) => {
    //   newChatObj[user._id] = {
    //     lastMessage: '',
    //     userId: user._id,
    //     firstName: user.firstName,
    //     lastName: user.lastName,
    //     unreadMessage: 0,
    //     lastMessageOn: new Date(0),
    //     lastMessageType: 'text',
    //     firestoreId: user.firestoreId,
    //     profileImage: user.profileImage,
    //     realRole: user.role.length ? user.role[0] : 'mentee',
    //     user: user.role.length ? user.role[0] : 'mentee',
    //     hasChat: true,
    //     type: 'DIRECT_MESSAGE',
    //     showInRecent: false,
    //   };
    // });
    // directChatList({ ...directChatList(), ...newChatObj });

    const queryRef = query(
      collection(db, 'groups'),

      where('members', 'array-contains', userDetails.firestoreId),
      where('status', '==', true),
      where('deleted', '==', false),
    );

    const unsubGroupChat = onSnapshot(queryRef, (querySnapshot) => {
      const data = [];
      let unreadCounts = 0;
      querySnapshot.forEach((documentSnapshot) => {
        data.push({
          ...documentSnapshot.data(),
          lastMessageOn: documentSnapshot.data().lastMessage
            ? new Date(documentSnapshot.data().lastMessage.createdDate?.toDate())
            : new Date(documentSnapshot.data().createdDate?.toDate()),
          lastMessage: documentSnapshot.data().lastMessage
            ? { ...documentSnapshot.data().lastMessage }
            : undefined,
          lastMessageType: documentSnapshot.data().lastMessage?.messageType || '',
          lastMessageText: documentSnapshot.data().lastMessage?.messageText || '',
          lastMessageCreatedDate: documentSnapshot.data().lastMessage?.createdDate?.toDate() || '',
          unreadMessage: documentSnapshot.data().unreadCounts
            ? documentSnapshot.data().unreadCounts[userDetails.firestoreId]
            : 0,
          type: 'GROUP_MESSAGE',
          totalMessages: documentSnapshot.data().totalMessages,
          showInRecent: true,
        });
        if (
          documentSnapshot.data().unreadCounts &&
          documentSnapshot.data().unreadCounts[userDetails.firestoreId]
        ) {
          unreadCounts += documentSnapshot.data().unreadCounts[userDetails.firestoreId];
        }
      });
      groupUnread(unreadCounts);
      groupChatList(data);
    });

    const unsubscribe = onSnapshot(
      collection(db, 'users', userDetails.firestoreId, 'userChats'),
      (docSnapshot) => {
        if (!docSnapshot.size) chatListLoadingVar(false);
        let newChatObj = {};
        docSnapshot.docChanges().forEach((chat) => {
          newChatObj = { ...newChatObj, ...setMentorDirectChatList(chat.doc.data()) };
        });
        directChatList({ ...directChatList(), ...newChatObj });
        chatListLoadingVar(false);
      },
    );
    return () => {
      unsubscribe();
      unsubGroupChat();
    };
  }, []);

  return null;
};

export default ListenToMessages;
