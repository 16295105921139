import {gql} from '@apollo/client'
export const GET_ROOM_ACCESS = gql`
  query getRoomAccess($room: String!,$user:String) {
    getMeeting(meetingId:$room){
        _id
        roomId
        startDate
        endDate
        createdBy{
          _id
        }
        participants{
            _id
            firstName
            lastName
            profileImage
        }
    }
    getRoomAccess(roomId:$room,userName:$user)
  }
`;