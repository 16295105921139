import { Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import React from 'react';
import BrandLogo from '../../Assets/Images/MentorHub-Logo.svg';
import themeColors from '../../Constants/themeColors';

const CustomAppBar = () => {
  const orgLogo = localStorage.getItem('organizationLogo')
  return (
    <>
      <AppBar
        className="header-top"
        position="relative"
        style={{ minHeight: 60, backgroundColor: themeColors.headerLeft }}>
        <Grid container style={{ minHeight: 60 }} justify="space-between">
          <Grid
            item
            style={{
              paddingLeft: 22,
              display: 'flex',
              alignItems: 'center',
              maxHeight: 60,
            }}>
            <React.Fragment>
              <img
                tabIndex="-1"
                area-hidden="true"
                src={orgLogo?orgLogo:BrandLogo}
                alt="logo"
                style={{ width:!orgLogo&& 120 }}
              />
            </React.Fragment>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
};

export default CustomAppBar;
