import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getCurrentUserDetails } from '../Api/Auth';
import { authenticateFirebase, registerPushToken } from '../Api/Firebase';
import axios from '../Config/axiosConfig';
import { userAuth } from '../Vars/auth';
export const UserAuthentication = () => {
  const [hasToken, setHasToken] = useState(false);
  const { data: userDetails, isLoading } = useQuery('userDetails', getCurrentUserDetails, {
    enabled: hasToken,
  });
  const userAuthVar = useReactiveVar(userAuth);

  useEffect(() => {
    if (isLoading) {
      userAuth({ ...userAuthVar, validatingUser: true });
    } else if (userDetails) {
      authenticateFirebase();
      registerPushToken(userDetails.data.user.id);
      userAuth({
        ...userAuthVar,
        userDetails: userDetails.data.user,
        isUserLoggedIn: true,
        validatingUser: false,
      });
      if(userDetails.data.user.organization.splashLogo!=null){
        localStorage.setItem('organizationLogo',userDetails.data.user.organization.splashLogo)

      }else{
        localStorage.removeItem('organizationLogo')
      }
    }
  }, [userDetails]);

  const validateUser = async () => {
    const token = localStorage.getItem('@authToken');
    if (token) {
      axios.defaults.headers.common = { authorization: `Bearer ${token}` };
      setHasToken(true);
    } else {
      userAuth({
        ...userAuthVar,
        userDetails,
        isUserLoggedIn: false,
        validatingUser: false,
      });
    }
  };

  return { validateUser };
};
