import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';
import { GENERATE_FIREBASE_TOKEN, UPDATE_DEVICE_TOKEN } from '../Apollo Queries/Auth';
import client from '../Config/apolloConfig';
// import axios from '../Config/axiosConfig';


// export async function authenticateFirebase() {
//   try {
//     const token = await axios.get('getFirebaseAuthToken');
//     const auth = getAuth();
//     const authResponse = await signInWithCustomToken(auth, token.data.firebaseToken);
//     return authResponse;
//   } catch (error) {
//     console.log(error);
//     throw error;
//   }
// }
export async function authenticateFirebase() {
  try {
    const {
      data: {
        generate_firebase_token: { token },
      },
    } = await client.mutate({
      context: { clientName: 'userService' },
      mutation: GENERATE_FIREBASE_TOKEN,
    });
    const auth = getAuth();
    const authResponse = await signInWithCustomToken(auth, token);
    return authResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// export async function registerPushToken(userId) {
//   try {
//     const messaging = getMessaging();
//     let permission = await Notification.requestPermission();
//     if (permission === 'granted') {
//       let deviceToken = await getToken(messaging, {
//         vapidKey:
//           'BNWG62AUrVStc0qIb2a9e33YTfz1cqBNNh0aRtnbuugWQB-TVyenBdRT92PBhDSSfEE9bBp9nxR5y8Ek9ORIg_w',
//       });
//       console.log('this is deviceToken', deviceToken);
//       await axios.post(`/user/${userId}/setDeviceToken`, {
//         deviceToken,
//         deviceId: '',
//       });
//     }
//   } catch (err) {
//     console.log('err------>', err);
//   }
// }

export async function registerPushToken() {
  try {
    const messaging = getMessaging();
    let permission = await Notification.requestPermission();
    if (permission === 'granted') {
      let deviceToken = await getToken(messaging, {
        vapidKey:
          'BGsNawCcDGIWf979W6qqPhqeb_2fY02hPi-R1AdtHitQ3v5ubfOdgcdNoD7sejq_oWlbxzbsnQj01J59x-8VBNA',
      });
      await client.mutate({
        context: { clientName: 'userService' },
        mutation: UPDATE_DEVICE_TOKEN,
        variables: {
          data: {
            deviceId: '',
            deviceToken,
            deviceType: 'WEB',
          },
        },
      });
    }
  } catch (err) {
    console.error('err------>', err);
  }
}

