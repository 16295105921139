import { useReactiveVar } from '@apollo/client';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { startCase } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import CourseIcon from '../../Assets/Images/Course.svg';
import FeedbackIcon from '../../Assets/Images/Feedback.svg';
import HomeIcon from '../../Assets/Images/Home.svg';
import MeetingIcon from '../../Assets/Images/Meeting.svg';
import BrandLogo from '../../Assets/Images/MentorHub-Logo.svg';
import MessageIcon from '../../Assets/Images/Message.svg';
import NotificationIcon from '../../Assets/Images/Notification.svg';
import TermsIcon from '../../Assets/Images/Terms.svg';
import ApptourIcon from '../../Assets/Images/Tutorial.svg';
import GuidesIcon from '../../Assets/Images/guides-icon.svg';
import LearnerIcon from '../../Assets/Images/learner.svg';
import ListenToChat from '../../Components/Messaging/DirectGroupContainer/listenToChats';
import themeColors from '../../Constants/themeColors';
import mentorAction from '../../Helper/Analytics/mentorAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import { useUnreadCount } from '../../Hooks/Messaging/useUnreadCount';
import { useNotificationCount } from '../../Hooks/Notification/useGetNotificationCount';
import getRoutes from '../../Routes/RoutePaths';
import { guideDrawerVar } from '../../Vars/Learners';
import { userAuth } from '../../Vars/auth';
import { sideBarVar } from '../../Vars/global';
import PageLoader from '../Common/PageLoader';
import CurrentUserInfo from './CurrentUserInfo';
import { showDetailVar, showFocus, showMainContent, showOnMouseClick } from './Vars';
const drawerWidth = 245;
const drawerWidthSmall = 75;
const useStyles = makeStyles((theme, options) => {
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      backgroundColor: themeColors.headerCenter,
      minHeight: 64,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: '100 !important',
      width: `100%`,
      left: '10 !important',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      left: '0 !important',
    },
    appBarFull: {
      marginLeft: drawerWidthSmall,
      width: `calc(100% - ${drawerWidthSmall}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      left: '0 !important',
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      backgroundColor: themeColors.headerLeft,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: drawerWidthSmall,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),

      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      backgroundColor: themeColors.headerLeft,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      marginTop: 64,
      height: 'calc(100vh - 64px)',
      overflowY: 'auto',
      position: 'relative',
    },
  };
});

const MenuLinks = [
  {
    title: 'Home',
    path: getRoutes.home,
    Icon: (props) => <ReactSVG src={HomeIcon} {...props} />,
  },
  {
    title: 'Mentees',
    path: getRoutes.learners,
    Icon: (props) => <ReactSVG src={LearnerIcon} {...props} />,
  },
  {
    title: 'Messages',
    path: getRoutes.messages,
    Icon: (props) => (
      <Badge
        badgeContent={props.unreadnotifcount > 0 ? props.unreadnotifcount : 0}
        sx={{
          '& .MuiBadge-badge': {
            color: 'white',
            backgroundColor: 'red',
            borderRadius: '10px',
          },
        }}>
        <ReactSVG src={MessageIcon} {...props} />
      </Badge>
    ),
  },
  {
    title: 'Meetings',
    path: getRoutes.meetings,
    Icon: (props) => <ReactSVG src={MeetingIcon} {...props} />,
    isDisabled: false,
  },
  // {
  //   title: 'Referral Task',
  //   path: getRoutes.referralTask,
  //   Icon: (props) => <ReactSVG src={ReferralTaskIcon} {...props} />,
  // },
  {
    title: 'Notifications',
    path: getRoutes.notifications,
    Icon: (props) => (
      <Badge
        badgeContent={props.unreadnotifcount}
        sx={{
          '& .MuiBadge-badge': {
            color: 'white',
            backgroundColor: 'red',
          },
        }}>
        <ReactSVG src={NotificationIcon} {...props} />
      </Badge>
    ),
  },
  {
    title: 'Resources',
    path: getRoutes.safetyInformation,
    Icon: (props) => <ReactSVG src={CourseIcon} {...props} />,
  },
  // {
  //   title: 'About',
  //   path: getRoutes.about,
  //   Icon: (props) => <ReactSVG src={AboutIcon} {...props} />,
  // },
  // {
  //   title: 'How it works',
  //   path: getRoutes.howItWorks,
  //   Icon: (props) => <ReactSVG src={HowIcon} {...props} />,
  // },
  {
    title: 'Terms & Conditions',
    path: getRoutes.termsAndConditions,
    Icon: (props) => <ReactSVG src={TermsIcon} {...props} />,
  },
  // {
  //   title: 'Features',
  //   path: getRoutes.features,
  //   Icon: (props) => <ReactSVG src={FeaturesIcon} {...props} />,
  // },
  {
    title: 'App Tutorial',
    path: getRoutes.appTour,
    Icon: (props) => <ReactSVG src={ApptourIcon} {...props} />,
  },
  {
    title: 'Feedback',
    isFeedback: true,
    path: '',
    Icon: (props) => <ReactSVG src={FeedbackIcon} {...props} />,
  },
];

const getHeaderTitle = {
  [getRoutes.home]: 'Home',
  [getRoutes.learners]: 'Mentees',
  [getRoutes.learnerDetails]: 'Mentee Details',
  [getRoutes.meetings]: 'Meetings',
  [getRoutes.safetyInformation]: 'Resources',
  [getRoutes.resourceDetails]: 'Resources',
  [getRoutes.notifications]: 'Notifications',
  [getRoutes.about]: 'About',
  [getRoutes.appTour]: 'App Tour',
  [getRoutes.features]: 'Features',
  [getRoutes.howItWorks]: 'How it works',
  [getRoutes.termsAndConditions]: 'Terms & Conditions',
  [getRoutes.referrals]: 'Referrals',
  [getRoutes.emergency]: 'Emergency Information',
  [getRoutes.messages]: 'Messages',
  [getRoutes.referralTask]: 'Referral Task',
  [getRoutes.checkInLogs]: 'Check-In Logs',
  [getRoutes.profile]: 'My Profile',
};

export default function MiniDrawer(props) {
  const chatUnread = useUnreadCount();
  const showDetail = useReactiveVar(showDetailVar);
  const showStatus = useReactiveVar(showMainContent);
  const location = useLocation();
  const showSkip = useReactiveVar(showOnMouseClick);
  const userAuthVar = useReactiveVar(userAuth);
  const { userDetails } = userAuthVar;
  const sideBar = useReactiveVar(sideBarVar);
  const { open } = sideBar;
  const params = useParams();
const orgLogo = localStorage.getItem('organizationLogo')
  const { count } = useNotificationCount();
  const classes = useStyles(props);
  const navigate = useNavigate();

  const drawerOpen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  useEffect(() => {
    if (!drawerOpen) handleDrawerClose();
    else handleDrawerOpen();
  }, [drawerOpen]);

  const unreadNotifCount = 0;

  const handleDrawerOpen = () => {
    sideBarVar({ open: true });
  };

  const handleDrawerClose = () => {
    sideBarVar({ open: false });
  };

  const pathMatch = (path) => {
    const currentPathSplit = location.pathname.split('/')[1];
    const menuPathSplit = path.split('/')[1];
    return currentPathSplit === menuPathSplit;
  };

  useEffect(() => {
    handleIsMeetingDisabled();
  }, []);

  const handleIsMeetingDisabled = () => {
    if (!userDetails.mentorInPrograms[0].videoEnabled) {
      let index = MenuLinks.findIndex((fi) => fi.title === 'Meetings');
      if (index !== -1) MenuLinks[index].isDisabled = false;
    }
  };

  return (
    <div className={classes.root}>
      {/* <Tour steps={steps} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} /> */}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
          [classes.appBarFull]: !open,
        })}>
        <Toolbar>
          <ListenToChat />
          <Grid container alignItems="center" justifyContent={'space-between'}>
            {!showDetail ? (
              <Grid item>
                <Typography
                  id="title-head"
                  tabIndex="-1"
                  onBlur={() => {
                    let element = document.getElementById(
                      `${getHeaderTitle[location.pathname]}-main`,
                    );
                    if (element) {
                      document.getElementById(`${getHeaderTitle[location.pathname]}-main`).focus();
                      // showMainContent(true)
                      showFocus({});
                    }
                  }}
                  variant="h1"
                  noWrap>
                  <strong>{getHeaderTitle[location.pathname]}</strong>
                </Typography>
              </Grid>
            ) : (
              <React.Fragment>
                {showDetail?.routeFrom === 'meetings' ? (
                  <>
                    <Grid item>
                      <Typography id="title-head" tabIndex="-1" variant="h1" noWrap>
                        Meetings
                      </Typography>
                    </Grid>
                    <Grid>
                      <Grid>{moment(Number(showDetail?.startDate)).format('ddd, DD MMM')}</Grid>
                      <Grid>
                        {`${moment(Number(showDetail?.startDate)).format('h:mm A')}  -  ${moment(
                          Number(showDetail?.endDate),
                        ).format('h:mm A')} `}
                      </Grid>
                    </Grid>
                  </>
                ) : showDetail?.routeFrom === 'referrals-details' ? (
                  <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <ArrowBackIosIcon
                      style={{ cursor: 'pointer' }}
                      alt="Back"
                      onClick={() => {
                        showDetailVar(null);
                        navigate(`learners/${params.id}`, {
                          state: { routeFrom: 'referrals-details' },
                        });
                      }}
                    />
                    <div
                      style={{
                        fontFamily: 'Lato',
                        fontWeight: '700',
                        fontSize: '18px',
                        lineHeight: '22px',
                      }}>
                      {showDetail?.referralDetails?.referralTitle}
                    </div>
                  </div>
                ) : (
                  <div
                    className="header-wrap"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}>
                    <div className="header-detail">
                      <Grid item>
                        <Typography variant="h1" noWrap>
                          <span className="referral-title">
                            <ArrowBackIosIcon
                              style={{ cursor: 'pointer' }}
                              alt="Back"
                              onClick={() => {
                                showDetailVar(null);
                                navigate(getRoutes.learners);
                              }}
                            />
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h1" noWrap>
                          <span className="referral-title">
                            <Avatar src={showDetail?.profileImage}>
                              {showDetail?.firstName?.charAt(0).toUpperCase()}
                            </Avatar>
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div noWrap>
                          <div className="referral-header-title-wrapper">
                            <Grid container alignItems="center" justifyContent={'space-between'}>
                              <Grid item xs={12} className="name">
                                {`${startCase(showDetail.firstName)} ${startCase(
                                  showDetail.lastName,
                                )}`}
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Grid>
                    </div>
                    {showDetail?.fromRoute === 'guides' && (
                      <Button
                        onClick={() => guideDrawerVar(true)}
                        variant="contained"
                        style={{
                          textTransform: 'none',
                          color: 'white',
                          paddingTop: '5px',
                        }}>
                        <img src={GuidesIcon} alt="guides" />
                        &nbsp; &nbsp; Add Guide
                      </Button>
                    )}
                  </div>
                )}
              </React.Fragment>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}>
        <div
          className={classes.toolbar}
          style={{
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
          }}>
          {orgLogo && drawerOpen ? (
            <img
              src={orgLogo}
              style={{ height: 50, paddingLeft: '10px' }}
              alt="Organizaion Logo"
            />
          ) : (
            drawerOpen && (
              <img src={BrandLogo} style={{ padding: '10px', width: '116px' }} alt="Brand Logo" />
            )
          )}
        </div>
        <Divider style={{ backgroundColor: '#464646', zIndex: 1 }} />
        <List component="nav" className="left-menu" tabIndex="0">
          {MenuLinks.filter((fl) => {
            if (fl.title === 'Meetings' && !userDetails.mentorInPrograms[0].videoEnabled) {
            } else return fl;
          }).map((item, index) =>
            item.isFeedback ? (
              <ListItem
                button
                component={'a'}
                href={'mailto:support@mentorhubapp.org'}
                key={index}
                onClick={() => {
                  trackEvent('click', {
                    screen: screenNames.FeedBack,
                    Action: mentorAction.MAIL_FEEDBACK,
                  });
                }}>
                <div className={pathMatch(item.path) ? 'list-wrap active' : 'list-wrap'}>
                  <ListItemIcon aria-hidden="true" sx={{ minWidth: '0px' }}>
                    <Grid
                      aria-hidden="true"
                      style={{
                        display: 'flex',
                        // backgroundColor: pathMatch(item.path)
                        //   ? themeColors.secondary
                        //   : 'transparent',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 5,
                        width: 30,
                        height: 30,
                        borderRadius: 100,
                      }}>
                      {item.Icon({
                        unreadnotifcount: unreadNotifCount,
                        className: 'menu-item-active',
                      })}
                    </Grid>
                  </ListItemIcon>
                  {drawerOpen && <ListItemText primary={item.title} />}
                </div>
              </ListItem>
            ) : (
              <ListItem
                button
                key={index}
                onClick={() => {
                  navigate(item.path);
                  setTimeout(() => {
                    document.getElementById(`title-head`).focus();
                  }, 2000);
                }}>
                <div className={pathMatch(item.path) ? 'list-wrap active' : 'list-wrap'}>
                  <ListItemIcon
                    sx={{ minWidth: '0px' }}
                    aria-hidden={
                      item.title === 'Notifications' || item.title === 'Messages' ? 'true' : 'false'
                    }>
                    <Grid
                      aria-hidden={item.title === 'Notifications' ? 'false' : 'true'}
                      style={{
                        display: 'flex',
                        // backgroundColor:
                        //   pathMatch(item.path) && item.path !== ''
                        //     ? themeColors.secondary
                        //     : 'transparent',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 7,
                        width: 35,
                        height: 35,
                        borderRadius: '50%',
                      }}>
                      {item.Icon({
                        unreadnotifcount: item.title === 'Messages' ? chatUnread : count,
                        className: 'menu-item-active',
                      })}
                    </Grid>
                  </ListItemIcon>
                  {drawerOpen && <ListItemText primary={item.title} />}
                </div>
              </ListItem>
            ),
          )}
        </List>

        <Grid>
          <CurrentUserInfo userDetails={userDetails} open={open} />
        </Grid>
      </Drawer>
      <main className={classes.content}>
        <React.Suspense
          fallback={
            <div>
              <PageLoader />
            </div>
          }>
          {showStatus && (
            <Button
              aria-hidden={showSkip ? 'true' : 'false'}
              aria-live="polite"
              role="button"
              aria-label="Skip to Main Navigation"
              autoFocus={true}
              variant="outlined"
              color="primary"
              tabIndex="0"
              className="status-btn"
              style={{
                position: 'absolute',
                background: '#000',
                top: '10px',
                margin: 'auto',
                zIndex: 3000,
                color: '#fffffe',
                border: 'solid #c00 1px',
                left: '10px',
                minHeight: '20px',
              }}
              onKeyDown={(e) => {
                (e.code === 'Enter' || (e.ctrlKey && e.altKey && e.code === 'Space')) &&
                  showFocus({
                    path: location.pathname,
                    status: true,
                    page: getHeaderTitle[location.pathname],
                  });
              }}
              onBlur={() => showMainContent(false)}>
              Skip to Main Navigation
            </Button>
          )}
          {props.children}
        </React.Suspense>
      </main>
    </div>
  );
}
