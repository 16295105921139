import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import CustomAppBar from '../Components/Common/AppBar';
import PageLoader from '../Components/Common/PageLoader';
import { userAuth } from '../Vars/auth';
import ErrorBoundary from './ErrorBoundary';
import getRoutes from './RoutePaths';
const PublicRoutes = () => {
  const userAuthVar = useReactiveVar(userAuth);
  const { isUserLoggedIn, userDetails } = userAuthVar;
  const userHasPermission = userDetails && userDetails.role.includes('mentor');
  return isUserLoggedIn && userHasPermission ? (
    <Navigate to={getRoutes.home} />
  ) : (
    <React.Suspense fallback={<PageLoader />}>
      <CustomAppBar />
      <ErrorBoundary state={'public'}>
      <Outlet />
      </ErrorBoundary>
    </React.Suspense>
  );
};

export default PublicRoutes;
