import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PageLoader from '../Components/Common/PageLoader';
import { userAuth } from '../Vars/auth';
import getRoutes from './RoutePaths';

const ApptourRoute = () => {
  const userAuthVar = useReactiveVar(userAuth);
  const { isUserLoggedIn, userDetails } = userAuthVar;
  const userHasPermission = userDetails && userDetails.role.includes('mentor');

  return isUserLoggedIn ? (
    userHasPermission ? (
      <React.Suspense fallback={<PageLoader />}>
        <Outlet />
      </React.Suspense>
    ) : (
      <Navigate to={getRoutes.notAuthorized} />
    )
  ) : (
    <Navigate to={getRoutes.login} />
  );
};

export default ApptourRoute;
