import { gql } from '@apollo/client';

export const GET_ALL_NOTIFICATIONS = gql`
  query getMyNotifications($limit: Int, $skip: Int) {
    getMyNotifications(limit: $limit, skip: $skip) {
      _id
      message
      event
      readStatus
      createdDate
      eventData
    }
    getMyNotificationsCount
  }
`;

export const CHANGE_NOTIFICATION_READ_STATUS = gql`
  mutation updateReadStatus($notificationId: String!) {
    readNotification(id: $notificationId)
  }
`;
export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($notificationId: String!) {
    clearNotification(id: $notificationId)
  }
`;
export const DELETE_ALL_NOTIFICATION = gql`
  mutation clearAllNotification {
    clearAllNotification
  }
`;

export const GET_NOTIFICATIONS_COUNT = gql`
  query getUnreadCount {
    getUnreadCount
  }
`;
