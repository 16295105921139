import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { UPDATE_MEETING_STAUS } from '../../../Apollo Queries/Meetings';
import { userAuth } from '../../../Vars/auth';
import { meetingVar } from '../services/vars';
import { GET_ROOM_ACCESS } from './gql';

export const useRoomAccess = () => {
  const meetingId = useReactiveVar(meetingVar);
  const userAuthVar = useReactiveVar(userAuth);
  const { userDetails } = userAuthVar;

  const [updateMeetingStatus] = useMutation(UPDATE_MEETING_STAUS);

  const [getRoomAccess, { loading, data }] = useLazyQuery(GET_ROOM_ACCESS, {
    onCompleted: (res) => {
      let currentDate = new Date();
      let meetingStartTime = new Date(Number(res.getMeeting.startDate));
      if (res?.getMeeting)
        if (currentDate > meetingStartTime && userDetails?.id == res?.getMeeting?.createdBy?._id) {
          updateMeetingStatus({
            variables: {
              meetingId: meetingId,
              status: 'inProgress',
            },
          });
        }
    },
  });

  const completeMeeting = () => {
    let currentDate = new Date();
    let meetingStartTime = new Date(Number(data.getMeeting.startDate));
    if (data?.getMeeting)
      if (currentDate > meetingStartTime && userDetails.id == data?.getMeeting?.createdBy?._id) {
        updateMeetingStatus({
          variables: {
            meetingId: meetingId,
            status: 'completed',
          },
        });
      }
  };

  useEffect(() => {
    if (!meetingId) return;
    getRoomAccess({
      variables: {
        room: meetingId,
        user: userDetails.id,
      },
    });
  }, [meetingId]);
  return { loading, data, userDetails, completeMeeting };
};
