import { GENERATE_TOKEN } from '../Apollo Queries/Auth';
import client from '../Config/apolloConfig';
import axios from '../Config/axiosConfig';
import getRoutes from '../Routes/RoutePaths';
import { snackBar } from '../Vars/global';

const HandleAxiosError = async (error) => {
  // && !error.response.config.url.includes('loggedinApp')
  if (error.response.status === 401) {
    const refreshToken = localStorage.getItem('@refreshToken');
    if (refreshToken) {
      const refreshToken = localStorage.getItem('@refreshToken');
      client
        .mutate({
          mutation: GENERATE_TOKEN,
          variables: {
            refreshToken,
          },
          context: {
            clientName: 'userService',
            headers: {},
          },
        })
        .then(({ data }) => {
          localStorage.setItem('@authToken', data.generate_token.token);
          axios.defaults.headers.common = { Authorization: `Bearer ${data.generate_token.token}` };
          window.location.reload();
        });
    } else {
      snackBar({
        open: true,
        message: 'Session has expired. Please login back to continue',
        variant: 'warning',
      });
      delete axios.defaults.headers.common['Authorization'];
      localStorage.clear();
      setTimeout(() => {
        window.location.href = getRoutes.login;
      }, 100);
    }
  } else {
    const response = error.response;
    const data = response.data;
    if (data.error) throw data.error;
    throw error;
  }
};

export default HandleAxiosError;
