import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_NOTIFICATIONS_COUNT } from '../../Apollo Queries/Notifications';
export const useNotificationCount = () => {
  const [count, setCount] = useState(0);
  const { loading, data, refetch } = useQuery(GET_NOTIFICATIONS_COUNT, {
    pollInterval: 6000,
  });
  useEffect(() => {
    if (!loading && data) {
      setCount(data.getUnreadCount);
    }
  }, [loading, data]);
  return { count, refetch };
};
