import { useReactiveVar } from '@apollo/client';
import { groupUnread, directChatList } from '../../Vars/ChatList';

export const useUnreadCount = () => {
  const directChats = useReactiveVar(directChatList);
  const groupCount = useReactiveVar(groupUnread);
  const directSum = Object.values(directChats).reduce((total, currVal) => {
    if (currVal.unreadMessage >= 0) return total + currVal.unreadMessage;
    else return total + 0;
  }, 0);
  return groupCount + directSum;
};
