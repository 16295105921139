import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { Navigate, Outlet } from 'react-router-dom';
import LeftWing from '../Components/Common/LeftWing';
import PageLoader from '../Components/Common/PageLoader';
import Meetings from '../Components/Meeting/JoinMeeting';
import '../Components/Meeting/JoinMeeting/styles.css';
import { userAuth } from '../Vars/auth';
import ErrorBoundary from './ErrorBoundary';
import getRoutes from './RoutePaths';
const PrivateRoute = () => {
  const userAuthVar = useReactiveVar(userAuth);
  const { isUserLoggedIn, userDetails } = userAuthVar;
  const userHasPermission = userDetails && userDetails.role.includes('mentor');

  return isUserLoggedIn ? (
    userHasPermission !== undefined ? (
      userHasPermission ? (
        <React.Suspense fallback={<PageLoader />}>
          <ErrorBoundary state={'public'}>
            <LeftWing>
              <ErrorBoundary state={'private'}>
                <Meetings />
                <Outlet />
              </ErrorBoundary>
            </LeftWing>
          </ErrorBoundary>
        </React.Suspense>
      ) : (
        <Navigate to={getRoutes.notAuthorized} />
      )
    ) : (
      <PageLoader />
    )
  ) : (
    <Navigate to={getRoutes.login} />
  );
};

export default PrivateRoute;
