import { Box, Button, Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import SomethingWrongImg from '../Assets/Images/SomethingWrongImg.svg';
import getRoutes from './RoutePaths';
const HandleError = ({ children, state }) => {
  const navigate = useNavigate();
  const Fallback = (props) => {
    return (
      <Box
        role="alert"
        display={'flex'}
        flexDirection={'column'}
        gap={'5px'}
        alignItems={'center'}
        justifyContent={'center'}
        width={state === 'private' ? 'calc(100vw - 245px)' : '100vw'}
        height={state === 'private' ? 'calc(100dvh - 64px)' : '100dvh'}>
        <Typography variant="h2" sx={{ fontSize: '36px', fontWeight: '700' }}>
          Something went wrong!
        </Typography>
        <img src={SomethingWrongImg} alt="something wrong" />
        <Button
          variant="contained"
          onClick={props.resetErrorBoundary}
          sx={{
            fontSize: '16px',
            fontWeight: '700',
            textTransform: 'capitalize',
            width: '138px',
            color: 'white',
            marginBottom: '10px',
          }}>
          Try Again
        </Button>

        <Button
          variant="contained"
          onClick={() => navigate(getRoutes.home)}
          sx={{ fontSize: '16px', fontWeight: '700', textTransform: 'capitalize', color: 'white' }}>
          Go Back Home
        </Button>
      </Box>
    );
  };

  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
};

export default HandleError;
