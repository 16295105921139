import { useReactiveVar } from '@apollo/client';
import { snackBar } from '../Vars/global';

const ToggleSnackBar = () => {
  const snackbarVar = useReactiveVar(snackBar);

  const toggleSnackBar = (open = false, message, variant) => {
    snackBar({ ...snackbarVar, open, message, variant });
  };

  return toggleSnackBar;
};

export default ToggleSnackBar;
