import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { snackBar } from '../../Vars/global';
import { useReactiveVar } from '@apollo/client';
import ToggleSnackBar from '../../Helper/Global';

// Basic Variants used for Snackbar
// variants :  "error", "warning", "info" ,"success"
const CustomizedSnackbars = () => {
  const toggleSnackBar = ToggleSnackBar();
  const snackbarVar = useReactiveVar(snackBar);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    toggleSnackBar(false, '', '');
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={3000}
      open={snackbarVar.open}
      onClose={handleClose}>
      <MuiAlert onClose={handleClose} severity={snackbarVar.variant} elevation={6} variant="filled">
        {snackbarVar.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default CustomizedSnackbars;
