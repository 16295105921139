import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#27cad2',
    },
    secondary: {
      main: '#F8F8F8',
    },
    subTitle: {
      main: '#545454',
    },
  },
  typography: {
    fontFamily:
      "Lato,Avenir,Nunito,Nunito,source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace ",
    h2: {
      fontSize: '20px',
      textTransform: 'capitalize',
    },
    h4: {
      fontSize: '16px',
      textTransform: 'capitalize',
      color: '#000',
      fontWeight: 400,
    },
    h5: {
      fontSize: '14px',
    },
    subtitle1: {
      fontSize: '12px',
      textTransform: 'capitalize',
      color: '#545454',
      fontWeight: 400,
    },
  },

  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        minHeight: 50,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 4,
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: '4px !important',
        backgroundColor: '#F8F8F8 !important',
      },
    },
  },
});
// , 100%
// export default theme;
