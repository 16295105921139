import { gql } from '@apollo/client';

export const GENERATE_OTP = gql`
  mutation Generate_otp($countryCode: String!, $phoneNumber: String!) {
    generate_otp(country_code: $countryCode, phone_number: $phoneNumber) {
      message
      status
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation Verify_otp($object: VerifyOTPInput!) {
    verify_otp(object: $object) {
      firebase_token
      refresh_token
      token
    }
  }
`;

export const GENERATE_FIREBASE_TOKEN = gql`
  mutation Generate_firebase_token {
    generate_firebase_token {
      token
    }
  }
`;

export const UPDATE_DEVICE_TOKEN = gql`
  mutation Update_device_token_one($data: UpdateDeviceTokenInput!) {
    update_device_token_one(data: $data) {
      _id
      userId
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout {
      redirectLink
      status
    }
  }
`;

export const GENERATE_TOKEN = gql`
  mutation generate_token($refreshToken: String!) {
    generate_token(refresh_token: $refreshToken) {
      token
    }
  }
`;
