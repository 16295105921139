import { makeVar } from '@apollo/client';

export const snackBar = makeVar({
  open: false,
  message: '',
  variant: 'success',
});

export const sideBarVar = makeVar({
  open: true,
});
