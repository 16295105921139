export default{
  //Dashboard
  VIEW_HOME:'VIEW_HOME',
  VIEW_ALL_MESSAGES:'VIEW_ALL_MESSAGES',
  OPEN_CHAT_WITH_PM: 'OPEN_CHAT_WITH_PM',
  OPEN_CHAT_WITH_MSS: 'OPEN_CHAT_WITH_MSS',
  OPEN_CHAT_WITH_MENTOR: 'OPEN_CHAT_WITH_MENTOR',
  OPEN_GROUP_CHAT: 'OPEN_GROUP_CHAT',
  OPEN_CHAT_WITH_MENTEE:'OPEN_CHAT_WITH_MENTEE',
  VIEW_ALL_RESOURCES:'VIEW_ALL_RESOURCES',
  VIEW_ALL_MENTEES:'VIEW_ALL_MENTEES',
  OPEN_MENTEE_DETAIL:'OPEN_MENTEE_DETAIL',
  VIEW_RESOURCE_DETAILS: 'REDIRECT_TO_RESOURCES',
  CLOSE_RECOURCE_DETAIL:'CLOSE_RECOURCE_DETAIL',
  //Mentees

  OPEN_MEETING:'OPEN_MEETING_DRAWER',
  OPEN_CHAT_WITH_PROGRAM_CONTACTS:'OPEN_CHAT_WITH_PROGRAM_CONTACTS',
  OPEN_MEETING_DRAWER_WITH_PROGRAM_CONTACTS:'OPEN_MEETING_DRAWER_WITH_PROGRAM_CONTACTS',

  //Messages
  OPEN_CREATE_GROUP_DRAWER:'OPEN_CREATE_GROUP_DRAWER',
  OPEN_CONTACTS_DRAWER:'OPEN_CONTACTS_DRAWER',
  OPEN_CHAT:'OPEN_CHAT',
  CREATE_GROUP:'CREATE_GROUP',
  CLOSE_CREATE_GROUP_DRAWER:'CLOSE_CREATE_GROUP_DRAWER',
  CHOOSE_GROUP_IMAGE:'CHOOSE_GROUP_IMAGE',
  EDIT_GROUP:'EDIT_GROUP',
  CREATE_GROUP_WITH_MENTEES:'CREATE_GROUP_WITH_MENTEES',
  OPEN_GROUP_DETAIL_DRAWER:'OPEN_GROUP_DETAIL_DRAWER',
  OPEN_FALGGED_MESSAGE_DRAWER:'OPEN_FALGG_MESSAGE_DRAWER',
  OPEN_DELETED_MESSAGES_DRAWER:'OPEN_DELETED_MESSAGES_DRAWER',
  EDIT_GROUP_DRAWER_OPEN:'EDIT_GROUP_DRAWER_OPEN',
  ADD_MENTEE_DRAWER_OPEN:'ADD_MENTEE_DRAWER_OPEN',
  ADD_MENTEE:'ADD_MENTEE',
  DELETE_GROUP:'DELETE_GROUP',
  REMOVE_MENTEE:'REMOVE_MENTEE',
  ADD_BROADCAST_DRAWER_OPEN:'ADD_BROADCAST_DRAWER_OPEN',
  ADD_BROADCAST:'ADD_BROADCAST',
  EDIT_BROADCAST:'EDIT_BROADCAST',
  DELETE_BROADCAST_GROUP:'DELETE_BROADCAST_GROUP',

  //Meeting
  OPEN_MEETING:'OPEN_MEETING',
  SCHEDULE_MEETING:"SCHEDULE_MEETING",
  UPDATE_MEETING:'UPDATE_MEETING',
  CLOSE_MEETING_DRAWER:'CLOSE_MEETING_DRAWER',
  JOIN_MEETING:'JOIN_MEETING',
  //Notification
  CLEAR_ALL_NOTIFICATIONS:'CLEAR_ALL_NOTIFICATIONS',

  //AppTour
  NEXT_APP_TOUR: 'NEXT_APP_TOUR',
  PREVIOUS_APP_TOUR: 'PREVIOUS_APP_TOUR',
  CLOSE_APP_TOUR_MODAL: 'CLOSE_APP_TOUR_MODAL',

  //Profile

  EDIT_PROFILE:'EDIT_PROFILE',

  //Ftue
  FTUE_NEXT:'FTUE_NEXT',

  LOGIN:'LOGIN',
  VERIFY_OTP:'VERIFY_OTP',
  LOG_OUT:'LOG_OUT',

  //Feedback
MAIL_FEEDBACK:'MAIL_FEEDBACK'
  




  
  

  


   

}