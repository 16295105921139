import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import errorLink from './errorHandling';



// const errorLink = onError((error) => {

//   if (
//     error.networkError &&
//     error.networkError.result.errors[0].extensions.code === 'UNAUTHENTICATED'
//   ) {
//     const refreshToken = localStorage.getItem('@refreshToken');
//     if (refreshToken) {
     
//     } else {
     
//     }
//   }
// });
const video = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/graphql`,
});

const curriculum = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/curriculum-server`,
});

const referral = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/knwBaseSrvr`,
});
const broadcast = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/broadcast-server`,
});
const userService = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/user-service`,
});


const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('@authToken');
  return {
    headers: {
      ...headers,
      'x-token': token ? `${token}` : '',
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// GraphQL Init
const client = new ApolloClient({
  link: from([
    errorLink,
    authLink.concat(
      ApolloLink.split(
        (operation) => operation.getContext().clientName === 'referral',
        referral, // <= apollo will send to this if clientName is "third-party"
        ApolloLink.split(
          (operation) => operation.getContext().clientName === 'curriculum',
          curriculum,
          // video
          
          ApolloLink.split(
            (operation) => operation.getContext().clientName === 'broadcast',
            broadcast,
            ApolloLink.split(
              (operation) => operation.getContext().clientName === 'userService',
              userService,
              video,
            ),
          ),
        ), // <= otherwise will send to this
      ),
    ),
  ]),
  cache: new InMemoryCache(),
});

export default client;
