import { makeVar } from '@apollo/client';

export const userAuth = makeVar({
  isUserLoggedIn: false,
  validatingUser: true,
  userDetails: {},
  firebaseConfig: {},
  firebaseUserDetails: {},
  loadingStates: {
    firebaseConfig: true,
    firebaseUser: true,
  },
});

export const userLogin = makeVar({
  countryCode: '',
  phoneNumber: '',
  enabled: false,
});
