import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import React from 'react';
import './App.css';
import { UserAuthentication } from './Operations/Auth';
import Navigator from './Routes';
import './css/style.css';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DBURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MESUREMENTID,
};
const app = initializeApp(firebaseConfig);
getMessaging(app);
const App = () => {
  const { validateUser } = UserAuthentication();
  React.useEffect(() => {
    validateUser();
  }, []);

  return <Navigator />;
};

export default App;
